/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

//js
// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
var $ = require('jquery');
require('jquery-ui-bundle');

//materialize
//require('../materialize/css/materialize.min.css');
//var M = require('../materialize/js/materialize.min.js');
// require('materialize-css')
// import 'materialize-css/js/materialize.js';
// import 'materialize-css/bin/materialize.js';
//require('../theme/js/app.js');

// any CSS you require will output into a single css file (app.css in this case)
require('../css/app.scss');
require('../css/connexion.scss');
require('../css/dashboard.scss');
require('../css/menu.scss');
require('../css/dropzone.scss');
require('../css/utilisateurs.scss');
require('../css/popup.scss');
require('../css/forms.scss');
require('../css/chantier.scss');
require('../css/pictos.scss');
require('../css/print.scss');




//theme
require('../theme/css/font-awesome.min.css');
require('../theme/css/bootstrap.min.css');
require('../theme/css/bootstrap-theme.min.css');
require('../theme/css/angular-material.min.css');
require('../theme/css/main.css');



require('../css/jquery-ui.min.css');





//require('../theme/js/app');

//images
//require('../img/*');



$(document).ready(function() {
    $.datepicker.setDefaults({
        //$.datepicker.regional["fr"]
        closeText: 'Fermer',
        prevText: 'Précédent',
        nextText: 'Suivant',
        currentText: 'Aujourd\'hui',
        monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
        monthNamesShort: ['Janv.', 'Févr.', 'Mars', 'Avril', 'Mai', 'Juin', 'Juil.', 'Août', 'Sept.', 'Oct.', 'Nov.', 'Déc.'],
        dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
        dayNamesShort: ['Dim.', 'Lun.', 'Mar.', 'Mer.', 'Jeu.', 'Ven.', 'Sam.'],
        dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
        weekHeader: 'Sem.',
        dateFormat: 'dd/mm/yy',
        showOn: 'button',
        buttonImage: '/build/images/calendar.gif',
        buttonImageOnly: true,
    });
    $('.datepicker').datepicker();

    //$('select').formSelect();
    //var elems = document.querySelectorAll('select');
    //var options = document.querySelectorAll('select option');
    //var instances = M.FormSelect.init(elems, options);
});